import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import { Locale, localizeAsset, LocalizedAsset } from "../utils/region";
import { CenteredRow } from "./common/layout";
import { theme } from "../theme/theme";
import { LinkAsButton } from "./common/button";
import { SocialMediaMenu } from "./social-media-menu";

import JokoLogoSrc from "../images/joko.svg";
import CurveDefs from "../images/curve.inline.svg";

const BCorpLogoSrc: LocalizedAsset = {
  fr: require("../images/bcorp-logo-fr.png"),
  en: require("../images/bcorp-logo-en.png"),
};

const gtSMMediaQuery: string = "@media screen and (min-width: 30em)"; //480px
const ltSMMediaQuery: string = "@media screen and (max-width: 29.9375em)"; //479px
const gtLGMediaQuery: string = "@media screen and (min-width: 71.5em)"; //1144px

export const Footer = () => {
  const intl = useIntl();
  const locale = intl.locale as Locale;
  const footerLinks = useFooterLinks();
  return (
    <>
      <Wrapper>
        <StyledCenteredRow>
          <Logos>
            <JokoLogo src={JokoLogoSrc} alt="Joko" />
            <BCorpLogo src={localizeAsset(locale, BCorpLogoSrc)} alt="BCorp" />
          </Logos>
          <Nav>
            {footerLinks.map(
              (footerLinkGroup: FooterLinkModel[], i: number) => (
                <ul key={i}>
                  {footerLinkGroup.map(
                    (footerLink: FooterLinkModel, j: number) => (
                      <NavListItem key={j}>
                        {footerLink.to ? (
                          <StyledLink to={footerLink.to}>
                            {footerLink.label}
                          </StyledLink>
                        ) : (
                          <ExternalNavLink
                            href={footerLink.href}
                            target="_blank"
                          >
                            {footerLink.label}
                          </ExternalNavLink>
                        )}
                      </NavListItem>
                    )
                  )}
                </ul>
              )
            )}
            <div>
              <StyledLinkAsButton
                bigger
                openwork
                greyBorder
                href={process.env.WEBAPP_SIGN_IN_LINK}
                target="_blank"
                as="a"
              >
                {intl.formatMessage({ id: "home.footer.links.webApp" })}
              </StyledLinkAsButton>
              {intl.locale === "en" ? null : (
                <SocialMediaMenuWrapper>
                  <SocialMediaMenu />
                </SocialMediaMenuWrapper>
              )}
            </div>
          </Nav>
        </StyledCenteredRow>
      </Wrapper>
      <CurveDefs />
    </>
  );
};
interface FooterLinkModel {
  label: string;
  to?: string;
  href?: string;
}

function useFooterLinks(): FooterLinkModel[][] {
  const intl = useIntl();
  const locale = intl.locale as Locale;
  const footerLinksInternal: FooterLinkModel[] = [
    {
      label: intl.formatMessage({ id: "home.footer.links.legals" }),
      to: "/legals",
    },
    {
      label: intl.formatMessage({ id: "home.footer.links.tos" }),
      to: locale === "en" ? "/tos-us" : "/tos",
    },
    {
      label: intl.formatMessage({ id: "home.footer.links.privacyPolicy" }),
      to: locale === "en" ? "/privacy-policy-us" : "/privacy-policy",
    },
  ];
  let footerLinksExternal: FooterLinkModel[] = [
    {
      label: intl.formatMessage({ id: "home.footer.links.jobs" }),
      href: "https://www.notion.so/Meet-Joko-ef431ca9251a4a1e82924ee17423ed33",
    },
    {
      label: intl.formatMessage({ id: "home.footer.links.help" }),
      href: "https://support.joko.com",
    },
    {
      label: intl.formatMessage({ id: "home.footer.links.press" }),
      href: "mailto:press@hellojoko.com",
    },
  ];
  if (locale === "fr")
    footerLinksExternal = [
      {
        label: intl.formatMessage({ id: "home.footer.links.blog" }),
        href: "https://medium.com/jokoapp",
      },
      ...footerLinksExternal,
    ];
  return [footerLinksInternal, footerLinksExternal];
}

const Wrapper = styled.footer`
  border-top: 1px solid ${theme.palette.common.grey};
  padding: 2.125em 0 3.125em;

  ${ltSMMediaQuery} {
    text-align: center;
  }
`;

const StyledCenteredRow = styled(CenteredRow)`
  ${gtSMMediaQuery} {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }
`;

const Logos = styled.div`
  ${gtSMMediaQuery} {
    display: flex;
    align-items: top;
  }
`;

const JokoLogo = styled.img`
  height: 3.5em;

  ${gtSMMediaQuery} {
    margin-top: 1em;
  }

  ${ltSMMediaQuery} {
    width: 100%;
  }
`;

const BCorpLogo = styled.img`
  height: 4.5em;

  ${gtSMMediaQuery} {
    margin-top: 0.7em;
    margin-left: 5em;
  }

  ${ltSMMediaQuery} {
    margin: 1em 0 0.75em 0;
  }
`;

const Nav = styled.nav`
  ${gtSMMediaQuery} {
    display: flex;
    flex-wrap: wrap;
    align-items: top;

    & > * {
      margin-left: 5em;

      &:last-child {
        width: 100%;
      }
    }
  }

  ${gtLGMediaQuery} {
    & > :last-child {
      width: initial;
    }
  }
`;

const NavListItem = styled.li`
  margin-top: 1em;
`;

export const StyledLink = styled(Link)`
  color: ${theme.palette.common.black};
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.palette.primary.darker};
  }
`;

const ExternalNavLink = styled.a`
  color: ${theme.palette.common.black};
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${theme.palette.primary.darker};
  }
`;

const StyledLinkAsButton = styled(LinkAsButton)`
  min-width: 11.125em; //178 corresponding to social links list width
  margin-top: 2em;
  text-align: center;

  ${gtLGMediaQuery} {
    margin-top: 1em;
  }

  ${ltSMMediaQuery} {
    display: none;
  }
`;

const SocialMediaMenuWrapper = styled.div`
  margin-top: 2em;

  ${gtLGMediaQuery} {
    margin-top: 1em;
  }
`;
